import React from 'react';

import './navbar.scss';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import { TranslatableText } from './language';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { navigate } from 'gatsby';
import MobileNavBar from './mobile/navbar';

export default class Tab extends React.Component {
  static contextType = AppStateContext;

  navigateParent(e, href) {
    navigate(href);
  }
  navigateParentOnEnter(e, href) {
    if (e.key === 'Enter') navigate(href);
  }
  navigateChild(e, href) {
    e.stopPropagation();
    navigate(href);
  }
  render() {
    const context = this.context;
    const pageName = context.page;
    return (
      <Navbar bg="light" expand="lg" className="top-navbar">
        <Container className={'d-none d-lg-flex'}>
          <Nav className="me-auto justify-content-between w-100">
            <Nav.Link
              onKeyPress={e => this.navigateParentOnEnter(e, '/home/')}
              onClick={e => this.navigateParent(e, '/home/')}
              className={`${pageName === 'home' ? 'active' : ''}`}
            >
              <TranslatableText
                dictionary={{
                  english: 'What Is VABYSMO',
                  french: 'Qu’est-ce que VABYSMO',
                }}
              />
            </Nav.Link>
            <Nav.Link
              onKeyPress={e => this.navigateParentOnEnter(e, '/risk-management-measures/')}
              onClick={e => this.navigateParent(e, '/risk-management-measures/')}
              className={`${pageName === 'risk-management-measures' ? 'active' : ''}`}
            >
              <TranslatableText
                dictionary={{
                  english: 'Risk Management Measures\nSafety Information',
                  french: 'Mesures d’atténuation des risques\nRenseignements sur l’innocuité',
                }}
              />
            </Nav.Link>
            <Nav.Link
              onKeyPress={e => this.navigateParentOnEnter(e, '/ourvisioncare-program/')}
              onClick={e => this.navigateParent(e, '/ourvisioncare-program/')}
              className={`${pageName === 'ourvisioncare-program' ? 'active' : ''}`}
            >
              <TranslatableText
                dictionary={{
                  english: 'Our VISION Care Patient Assistance Program',
                  french: 'Programme d’assistance aux patients Nos soins de la VUE',
                }}
              />
            </Nav.Link>
            <Nav.Link
              onKeyPress={e => this.navigateParentOnEnter(e, '/community-support/')}
              onClick={e => this.navigateParent(e, '/community-support/')}
              className={`${pageName === 'community-support' ? 'active' : ''}`}
            >
              <TranslatableText
                dictionary={{
                  english: 'Community Support',
                  french: 'Soutien communautaire',
                }}
              />
            </Nav.Link>
          </Nav>
        </Container>
        <MobileNavBar />
      </Navbar>
    );
  }
}
