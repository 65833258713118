import React from 'react';
import './external-page-modal.scss';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { TranslatableText } from './language';

export default ({ isOpen, toggle, openLink }) => (
  <Modal isOpen={isOpen} toggle={toggle} className={'modal-lg'} id="external-page-modal">
    <ModalHeader toggle={toggle}>
      <TranslatableText
        dictionary={{ english: 'You are now leaving Vabysmo.ca', french: 'Vous quittez maintenant Vabysmo.ca' }}
      />
    </ModalHeader>
    <ModalBody>
      <div className="external-container">
        <TranslatableText
          dictionary={{
            english:
              'You are now leaving the website of Hoffmann-La Roche Limited ("Roche Canada"). Links to all external sites are provided as a resource to our visitors. Roche Canada assumes no responsibility for the content of these sites. Roche Canada has no control over these sites and the opinions, claims or comments contained in these sites should not be attributed to Roche Canada, unless otherwise specified.',
            french:
              'Vous quittez maintenant le site Web de Hoffmann-La Roche Limited/Limitée (« Roche Canada »). Les liens vers tous les sites externes sont fournis à titre de ressources aux visiteurs de notre site. Roche Canada n’assume aucune responsabilité quant au contenu de ces sites. Roche Canada n’exerce aucun contrôle sur ces sites, et les opinions, déclarations ou commentaires formulés dans ces sites ne doivent pas être attribués à Roche Canada, sauf indication contraire.',
          }}
        />

        <div className="btns-container">
          <button onClick={openLink} className="btn-open">
            <TranslatableText dictionary={{ english: 'I understand', french: 'Je comprends' }} />
          </button>
          <button onClick={toggle} className="btn-back">
            <TranslatableText dictionary={{ english: 'Take me back', french: 'Retourner sur le site' }} />
          </button>
        </div>
      </div>
    </ModalBody>
  </Modal>
);
