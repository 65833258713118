import React, { Component } from 'react';

import './skip-to-main.scss';
import { TranslatableText } from './language';

class SkipToMain extends Component {
  componentDidMount() {
    document.getElementById('skip-to-main').addEventListener('click', e => {
      e.preventDefault();
      document.getElementById('skip-to-main').blur();
      document.getElementById('main-content').scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  render() {
    return (
      <a id="skip-to-main" className="d-none d-xl-flex justify-content-center align-items-center" href="#main-content">
        <TranslatableText
          dictionary={{
            english: 'SKIP TO MAIN CONTENT',
            french: 'ALLER AU CONTENU',
          }}
        />
      </a>
    );
  }
}

export default SkipToMain;
