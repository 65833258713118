import React, { Component, Fragment } from 'react';
import ExternalPageModal from './external-page-modal';
import './external-link.scss';
export default class ExternalLink extends Component {
  state = { isOpen: false };

  open(e) {
    e.preventDefault();

    this.setState({ isOpen: true });
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  openLink() {
    this.setState({ isOpen: false });

    window.open(this.props.href, '_blank', 'noopener,noreferrer');
  }

  render() {
    return (
      <>
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a onClick={this.open.bind(this)} className="btn-link" href="#">
          {this.props.children}
        </a>
        {/* eslint-enable jsx-a11y/anchor-is-valid */}
        <ExternalPageModal
          isOpen={this.state.isOpen}
          toggle={this.toggle.bind(this)}
          openLink={this.openLink.bind(this)}
        />
      </>
    );
  }
}
