import React from 'react';
import './main-header.scss';

import { LanguageSwitcher, TranslatableText, TranslatedBlock } from './language';
import { Link } from 'gatsby';
import SkipToMain from './skip-to-main';
import FontSizeChanger from './font-size-changer.js';
import { default as VabysmoLogoEN } from '../images/Vabysmo_logo_EN.svg';
import { default as VabysmoLogoFR } from '../images/Vabysmo_logo_FR.svg';
import { default as Roche_logo } from '../images/Roche.svg';

import PropTypes from 'prop-types';
import Navbar from './navbar';

export default class MainHeader extends React.Component {
  render() {
    return (
      <header className={'main-header'}>
        <div className={'menu-wrapper container'}>
          <div className={'row align-items-center '}>
            <h1 className={'branding col-8 col-md order-1'}>
              <Link to="/" className={'custom-logo-link'}>
                <TranslatedBlock language="english">
                  <img src={VabysmoLogoEN} alt="Vabysmo logo" className={'logo-img img-fluid'} />
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <img src={VabysmoLogoFR} alt="Vabysmo logo" className={'logo-img img-fluid'} />
                </TranslatedBlock>
                <span className={'site-name'}>{this.props.siteTitle}</span>
              </Link>
            </h1>
            <SkipToMain />
            <div className={'top-right-container col-12 col-xl-5 order-3 order-xl-2'}>
              <div className="d-block d-xl-flex justify-content-xl-end mb-2 mt-lg-2 pb-1">
                {this.props.showNavbar && (
                  <>
                    <TranslatedBlock language="english">
                      <a
                        href="https://www.rochecanada.com/PMs/vabysmo/Vabysmo_PM_CIE.pdf"
                        className="d-flex align-items-center pmi-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Patient Medication Information
                      </a>
                    </TranslatedBlock>
                    <TranslatedBlock language="french">
                      <a
                        href="https://www.rochecanada.com/PMs_Fr/vabysmo/Vabysmo_PM_CIF.pdf"
                        className="d-flex align-items-center pmi-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Renseignements destinés aux patients
                      </a>
                    </TranslatedBlock>
                  </>
                )}
                <LanguageSwitcher />
              </div>
            </div>
            <div className={'col col-xl-auto order-2 order-xl-3'}>
              <div className={'font-size-changer-container d-none d-md-flex'}>
                <span className={'text-size-label'}>
                  <TranslatableText
                    dictionary={{
                      english: 'Text Size:',
                      french: 'Taille des caractères :',
                    }}
                  />
                </span>
                <FontSizeChanger
                  className={'font-size-changer'}
                  targets={['.nav-link', 'section', 'main', '.disclaimer', '.quote']}
                  options={{ stepSize: 1, range: 5 }}
                />
              </div>
            </div>
            <div className={'col-3 col-md-auto col-xl-1 offset-sm-0 order-2 order-xl-3 d-flex justify-content-end'}>
              <img src={Roche_logo} alt="Roche logo" className={'roche-logo img-fluid'} />
            </div>
          </div>
        </div>
        {this.props.showNavbar && <Navbar />}
      </header>
    );
  }
}

MainHeader.propTypes = {
  siteTitle: PropTypes.string,
  showNavbar: PropTypes.bool,
};

MainHeader.defaultProps = {
  siteTitle: '',
  showNavbar: true,
};
