import React from 'react';
import './footer.scss';

import { Link } from 'gatsby';
import { AppStateContext } from '../contexts/app-state-provider.context';
import { TranslatableText, TranslatedBlock } from './language';
import ExternalLink from './external-link';

import IMCLogoWhiteEN from 'src/images/IMC_logo_white_EN.png';
import IMCLogoWhiteFR from 'src/images/IMC_logo_white_FR.png';
import PAABLogoEN from 'src/images/PAAB_logo_color_EN.png';
import PAABLogoFR from 'src/images/PAAB_logo_color_FR.png';

export default class Footer extends React.Component {
  static contextType = AppStateContext;

  handleCookiePreferencesClick = event => {
    event.stopPropagation();
    window.OneTrust.ToggleInfoDisplay();
  };

  render() {
    const currentYear = new Date().getFullYear();

    let rpgCodeDict = {
      english: '86804E24v',
      french: '86804F24v',
    };

    const rpgCode = rpgCodeDict ? (
      <div className="container rpg-code-container">
        <div className={'rpg-code'}>
          <TranslatableText dictionary={rpgCodeDict} />
        </div>
      </div>
    ) : null;

    return (
      <AppStateContext.Consumer>
        {context => (
          <footer id="colophon" className="site-footer login-footer pb-4">
            <TranslatedBlock language="english">
              <div className="container">
                <div className="row">
                  <div className="d-flex flex-column flex-sm-row footer-nav col-12 pt-5 pb-2">
                    <ul>
                      <li>
                        <a href="https://rocheterms.ca/en/privacy-policy" rel="noreferrer" target="_blank">
                          PRIVACY POLICY
                        </a>
                      </li>
                      <li>
                        <a href="https://rocheterms.ca/en/terms-and-conditions" rel="noreferrer" target="_blank">
                          TERMS AND CONDITIONS
                        </a>
                      </li>
                      <li>
                        <button className="ot-sdk-show-settings" onClick={this.handleCookiePreferencesClick}>
                          COOKIE PREFERENCES
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-7 d-flex flex-column justify-content-between">
                    <p>
                      This website is neither intended nor designed to record or report adverse event information. If
                      you have a suspected side effect or problem to report regarding a Roche product, please email
                      mississauga.canada_medinfo@roche.com or call 1-888-762-4388. You can also report adverse events
                      relating to any product to Health Canada directly by going to the Canada Vigilance Program website
                      at{' '}
                      {context.section === 'login' ? (
                        'https://www.canada.ca/en/health-canada/services/drugs-health-products/medeffect-canada/adverse-reaction-reporting.html'
                      ) : (
                        <ExternalLink href="https://www.canada.ca/en/health-canada/services/drugs-health-products/medeffect-canada/adverse-reaction-reporting.html">
                          https://www.canada.ca/en/health-canada/
                          <wbr />
                          services/
                          <wbr />
                          drugs-health-products/
                          <wbr />
                          medeffect-canada/
                          <wbr />
                          adverse-reaction-reporting.html
                        </ExternalLink>
                      )}
                      .
                    </p>
                    <div className="mt-5">
                      <p className="mb-0">Copyright © {currentYear}, Hoffmann-La Roche Limited. All rights reserved.</p>
                      <p>
                        VABYSMO<sup>®</sup> is a registered trade-mark of F. Hoffmann-La Roche AG, used under license.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <p>
                      This website contains information intended only for residents of Canada who have been prescribed
                      VABYSMO. We accept no responsibility for users outside of Canada accessing portions of this
                      website which may be invalid or non-compliant with the laws, regulations or standards of the
                      user’s home country. Please see the{' '}
                      <a href="https://rocheterms.ca/en/terms-and-conditions" rel="noreferrer" target="_blank">
                        Terms & Conditions
                      </a>{' '}
                      and{' '}
                      <a href="https://rocheterms.ca/en/privacy-policy" rel="noreferrer" target="_blank">
                        Privacy Policy
                      </a>{' '}
                      for more details.
                    </p>
                    <div className="d-flex flex-column flex-sm-row mt-5">
                      <div className="paab-logo-container footer-logo">
                        <img src={PAABLogoEN} alt="PAAB logo" className={'paab-logo-img'} />
                      </div>

                      <div className="memberIMC-logo-container footer-logo">
                        <img src={IMCLogoWhiteEN} alt="IMC logo" className={'imc-logo-img'} />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-5">{rpgCode}</div>
                </div>
              </div>
            </TranslatedBlock>
            <TranslatedBlock language="french">
              <div className="container">
                <div className="row">
                  <div className="d-flex flex-column flex-sm-row footer-nav col-12 pt-5 pb-2">
                    <ul>
                      <li>
                        <a href="https://rocheterms.ca/fr/privacy-policy" rel="noreferrer" target="_blank">
                          ÉNONCÉ DE CONFIDENTIALITÉ
                        </a>
                      </li>
                      <li>
                        <a href="https://rocheterms.ca/fr/terms-and-conditions" rel="noreferrer" target="_blank">
                          CONDITIONS D’UTILISATION
                        </a>
                      </li>
                      <li>
                        <button className="ot-sdk-show-settings" onClick={this.handleCookiePreferencesClick}>
                          PRÉFÉRENCES EN MATIÈRE DE TÉMOINS
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-7 d-flex flex-column justify-content-between">
                    <p>
                      Ce site Web n’a pas été conçu pour l’enregistrement ou la déclaration de l’information sur les
                      effets indésirables et ne peut donc être utilisé à cette fin. Si vous devez déclarer un effet
                      secondaire ou un problème soupçonné concernant un des produits de Roche, veuillez envoyer un
                      courriel à mississauga.canada_medinfo@roche.com ou téléphoner au 1-888-762-4388. Vous pouvez aussi
                      déclarer les effets indésirables se rapportant à n’importe quel produit directement à Santé Canada
                      en visitant le site Web du Programme Canada Vigilance au{' '}
                      {context.section === 'login' ? (
                        'https://www.canada.ca/fr/sante-canada/services/medicaments-produits-sante/medeffet-canada/declaration-effets-indesirables.html'
                      ) : (
                        <ExternalLink href="https://www.canada.ca/fr/sante-canada/services/medicaments-produits-sante/medeffet-canada/declaration-effets-indesirables.html">
                          https://www.canada.ca/fr/sante-canada/
                          <wbr />
                          services/
                          <wbr />
                          medicaments-produits-sante/
                          <wbr />
                          medeffet-canada/
                          <wbr />
                          declaration-effets-indesirables.html
                        </ExternalLink>
                      )}
                      .
                    </p>
                    <div className="mt-5">
                      <p className="mb-0">© {currentYear} Hoffmann-La Roche Limited/Limitée. Tous droits réservés.</p>
                      <p>
                        VABYSMO<sup>®</sup> est une marque déposée de F. Hoffmann-La Roche AG, utilisée sous licence.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <p>
                      L’information contenue dans le présent site Web s’adresse uniquement aux résidents du Canada
                      auxquels on a prescrit VABYSMO. Nous n’assumons aucune responsabilité envers les utilisateurs de
                      l’extérieur du Canada qui consultent certaines parties de ce site Web, puisque ces dernières
                      peuvent être invalides dans leur pays ou non conformes aux lois, aux règlements ou aux normes qui
                      y sont en vigueur. Veuillez consulter les{' '}
                      <a href="https://rocheterms.ca/fr/terms-and-conditions" rel="noreferrer" target="_blank">
                        Conditions d’utilisation
                      </a>{' '}
                      et l’
                      <a href="https://rocheterms.ca/fr/privacy-policy" rel="noreferrer" target="_blank">
                        Énoncé de confidentialité
                      </a>{' '}
                      pour en savoir plus.
                    </p>
                    <div className="d-flex flex-column flex-sm-row mt-5">
                      <div className="paab-logo-container footer-logo">
                        <img src={PAABLogoFR} alt="PAAB logo" className={'paab-logo-img'} />
                      </div>

                      <div className="memberIMC-logo-container footer-logo">
                        <img src={IMCLogoWhiteFR} alt="IMC logo" className={'imc-logo-img'} />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-5">{rpgCode}</div>
                </div>
              </div>
            </TranslatedBlock>
          </footer>
        )}
      </AppStateContext.Consumer>
    );
  }
}
