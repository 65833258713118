import React from 'react';

import './menu.scss';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import { TranslatableText } from '../language';
import { Nav } from 'react-bootstrap';
import { Link } from 'gatsby';

export default class MobileMenu extends React.Component {
  static contextType = AppStateContext;

  render() {
    return (
      <div className={'mobile-sidebar-menu d-flex flex-column ' + (this.props.show ? 'show-mobile-menu' : '')}>
        <Nav.Link as={Link} to="/home" onClick={this.context.closeMobileMenu}>
          <TranslatableText
            dictionary={{
              english: 'What Is VABYSMO',
              french: 'Qu’est-ce que VABYSMO',
            }}
          />
        </Nav.Link>
        <Nav.Link as={Link} to="/risk-management-measures" onClick={this.context.closeMobileMenu}>
          <TranslatableText
            dictionary={{
              english: 'Risk Management Measures\nSafety Information',
              french: 'Mesures d’atténuation des risques Renseignements sur l’innocuité',
            }}
          />
        </Nav.Link>
        <Nav.Link as={Link} to="/ourvisioncare-program" onClick={this.context.closeMobileMenu}>
          <TranslatableText
            dictionary={{
              english: 'Our VISION Care Patient Assistance Program',
              french: 'Programme d’assistance aux patients Nos soins de la VUE',
            }}
          />
        </Nav.Link>
        <Nav.Link as={Link} to="/community-support" onClick={this.context.closeMobileMenu}>
          <TranslatableText
            dictionary={{
              english: 'Community Support',
              french: 'Soutien communautaire',
            }}
          />
        </Nav.Link>
      </div>
    );
  }
}
