import React from 'react';

import './navbar.scss';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import MobileMenu from './menu';

export default class MobileNavbar extends React.Component {
  static contextType = AppStateContext;

  render() {
    return (
      <div className={'w-100 d-lg-none'}>
        <div className={'container'}>
          <div className={'mobile-container d-flex justify-content-between w-100 d-lg-none h-100 align-items-center'}>
            <div className={'mobile-menu-button'}>
              {/*<Navbar.Toggle aria-controls="mobileMenu" />*/}
              <button
                type="button"
                className={
                  'd-flex align-items-baseline navbar-toggle collapsed ' +
                  (this.context.mobileMenuOpen ? 'wastoggled' : '')
                }
                onClick={this.context.toggleMobileMenu}
              >
                <span className=""></span>
                <span className=""></span>
                <span className=""></span>
                <span className="menu-text">Menu</span>
              </button>
            </div>
          </div>
        </div>
        <MobileMenu show={this.context.mobileMenuOpen} />
      </div>
    );
  }
}
